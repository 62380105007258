export const apiMessage = {
  connect_brand: 'Please connect at least one brand',
  NOT_ACCESS: 'Your access permission is denied for this page, contact brand for more information',
  DELETED: (module: string) => `${module} has been successfully deleted`,
  orders: {
    details: {
      PRODUCT_DEACTIVE: 'Some product(s) are deactivated or deleted',
      PRODUCT_COMBINATION_DEACTIVE: 'Some product\'s combinations are deactivated',
      EMPTY_COMBINATION: 'Some product don\'t have combinations, Please remove them',
      TRASHED_COMBINATION: 'Some combinations are deleted in products',
      NO_PRODUCT: 'Please add minimum one product',
      TRASHED_PRODUCTS: 'Please add minimum one product',
      INVALID_ORDER_ID: 'Order no is invalid',
      RETAILER_MISSING: 'Please select retailer',
      RETAILER_INACTIVE: 'Selected retailer is not active',
      DISTRIBUTOR_INACTIVE: 'Selected distributor is inactive or blocked',
      RETAILER_DELETED: 'Selected retailer is deleted',
      PLEASE_REMOVE_DEACTIVATED_PRODUCT: 'Please remove deactivated products before submit.',
      IN_PROGRESS: 'Order already in process',
      PRODUCT_COMBINATION_DEACTIVE_PC_THRESHOLD_REACHED: 'Some product combinations are not active or threshold reached',
      DEACTIVATED_THRESHOLD_REACHED: 'Some product combinations are not active or threshold reached',
      THRESHOLD_REACHED: 'Product combination is out of stock',
      DEACTIVATED: 'Product combination is deactivate',
      NOT_FOUND: 'Product not found.',
      PRODUCTS_NOT_IN_CATALOG: 'Some products are not available in catalogue',
      ALREADY_FLAGGED: (flag: any) => `Order can\'t be update because Order is already mark as ${flag}`,
      NON_SELECT_FLAG: 'Please select one option',
      INVALID_CATELOG: 'This catalogue is Inactive/Expired'
    },
    import: {
      NOT_IMPORT: (count: any, total: any) => `${count} of ${total} barcodes are not available in this organization`,
      BARCODE_DOES_NOT_EXISTS: (count: any) => `<strong>${count}</strong> barcode(s) does not match with store's inventory`,
      BARCODE_NOT_CATEGORISED: (count: number) => `<strong>${count}</strong> barcode(s) in excel ${count > 1 ? 'are' : 'is'} not linked with categorised product(s).`,
      ALREADY_SUBMITTED: 'This order is already submitted.'
    },
    UNABLE_TO_EDIT: "You can't update this order.",
    ORG_UNABLE_TO_EDIT: "Contact brand admin or owner to edit order details.",
    UNABLE_TO_ZERO: "You can't update all the variation's qty to 0, if you wish to discard this order, kindly cancel it.",
    MORE_THAN_STOCK: "You can't order more than the limit. Threshold limit not allowing this qty",
    INVALID_QUANTITY: "Quantity must be between 1 to 999",
    ORDER_PRODUCT_COMBINATION_NOT_FOUND: "Order Product Combination is not available",
    ORDER_PRODUCT_NOT_FOUND: "Order Product is not available",
    PRODUCT_COMBINATION_NOT_FOUND: "Product Combination is not available",
    PRODUCT_NOT_FOUND: "Product is not available",
    INACTIVE_PRODUCT_COMBINATION: "Editing restricted for inactive product variation",
    INACTIVE_PRODUCT: "Editing restricted for inactive product",
    FLAGGED_ORDER: "Editing restricted for flagged, processing, delivered, or partially delivered orders.",
    MAX_25_ORDERS: 'You can export maximum 25 orders only at a time',
    // MORE_THAN_STOCK: (qty, futureStock) => `You can enter ${qty} more quantity than your previous one, i.e ${futureStock} is max limit for this variation`,
    GRADES: {
      EMPTY_VARIATION: 'Please add minimum one size',
    }
  },
  challans: {
    UNABLE_TO_EDIT: "You can't update all the variation's qty to 0, if you wish to discard this challan, kindly cancel it.",
    ALREADY_CANCELLED: 'You can\'t update a challan that has already been cancelled',
    ALREADY_IN_INVOICE: 'You can\'t update a challan that has already been invoiced',
    ORDER_FLAGGED: 'You can\'t update a challan whose order has been flagged',
    UNABLE_TO_DELETE: "You can't delete the variation because of minimum one variation is required.",
    VARIATION_NOT_FOUND: 'The selected product variation\'s availability has changed. Please review and update the challan if necessary.',
    cancelled_Error: {
      DEFAULT: `This challan has been cancelled since it contains invalid data`,
      ORDER_FLAGGED: `This challan has been cancelled since the order #orderNo was already marked as Cancelled/Closed/Transferred to ERP.`,
      ORDER_DELIVERED: `This challan has been cancelled since the order #orderNo was already marked as Delivered.`,
      ALL_ORDER_PRODUCT_DELETE: `This challan has been cancelled since the order #orderNo contains deleted products.`,
      ALL_PRODUCT_DELETED: 'This challan has been cancelled since it contains deleted products.',
      RETAILER_INACTIVE: `This challan has been cancelled since it contains inactive retailer.`,
      RETAILER_DELETED: `This challan has been cancelled since it contains deleted retailer.`,
      DISTRIBUTOR_INACTIVE: `This challan has been cancelled since it contains inactive distributor.`,
      DISTRIBUTOR_DELETED: `This challan has been cancelled since it contains deleted distributor.`,
      INVALID_RETAILER: `This challan has been cancelled since it contains inactive/deleted/blocked retailer.`,
      INVALID_DISTRIBUTOR: `This challan has been cancelled since it contains inactive/deleted distributor.`,
    },
  },
  distributor: {
    ALREADY_EXISTS: 'Phone number already exists',
    REFID_EXISTS: 'Reference ID already exists',
  },
  retailer: {
    ALREADY_EXISTS: 'Phone number already exists',
    REFID_EXISTS: 'Reference ID already exists',
    RETAILER_GROUP_CREATED: 'Retailer Group Created',
    RETAILER_GROUP_DELETED: 'Retailer Group Deleted',
    RETAILER_GROUP_UPDATED: 'Retailer Group Updated',
    RETAILER_GORUP_ASSIGNED: 'Retailer group assigned successfully to the selected retailers.',
    RETAILER_GORUP_UNASSIGNED: 'Retailer group removed successfully from the selected retailers.',
    GROUP_NAME_ALREADY_EXIST: 'Group Name Must Be Unique Within The Organization.',
  },
  catalogues: {
    INVALID_CREDENTIALS: 'Pin is invalid',
    ALREADY_EXPIRED: {
      title: 'This link you followed has been expired.',
      message: (brand: any, email: any) => `Please contact to brand ${email ? `<a href='mailto:${email}'>${brand}</a>` : `${brand}`} for more information`
    },
    import: {
      NOT_IMPORT: (count: any, total: any) => `${count} of ${total} barcodes are not available in this organization`,
    },
    orders: {
      BRAND_LOGIN: (brandName: string) => `You cannot place the order as you are not connected with ${brandName}`,
    }
  },
  products: {
    BARCODE_EXISTS: 'Barcode already exists',
    ERR_OUT_OF_RANGE: 'Image is out of cropper area. Please keep the image inside the cropper area',
    SKU_EXISTS: 'Product SKU already exists',
    TAGS_LIMIT: 'You can upload a maximum of 10 tags',
    GROUP_ALREADY_EXISTS: 'Size group name already exists',
    GROUP_SIZES_ALREADY_EXIST: 'Size group with same sizes already exists'
  },
  inventory: {
    manage_stock: {
      UNDEFINED_PRODUCTION_QTY: 'Stock In Production quantity is not defined yet',
      INVALID_PRODUCTION_QTY: 'Stock In Production quantity is less then entered quantity',
      INVALID_PLANNING_QTY: 'Production Planning quantity is less then entered quantity',
      UNDEFINED_PLANNING_QTY: 'Production Planning quantity is not defined yet',
    },
    import: {
      NOT_IMPORT: (count: any, total: any) => `${count} of ${total} barcodes are not available in this organization`,
    },
    batches: {
      error: {
        BATCH_NOT_FOUND: 'Batch is not available',
        BATCH_ALREADY_DELETED: 'Batch is already deleted.',
        BATCH_EMPTY: 'Batch is empty.',
        NO_PRODUCT_FOUND: 'No product(s) available.',
        NO_PRODUCT: 'No product(s) available.',
        BATCH_ALREADY_SUBMITTED: 'Batch is already submitted.',
        BATCH_ALREADY_CANCELLED: 'Batch is already cancelled.',
        FAILED_BATCH: '',
        COUNT_FETCH_FAILED: '',
        NO_SIZE_PRODUCT_FOUND: (batchType: any) => `You can\'t transfer "No Size" qty from ${batchType} to Current Stock.`,
        UNAUTHORIZED_USER: 'This user does not have the permission to modify this batch.',
        BATCH_ALREADY_UNDER_PROCESSING: 'Please wait while the batch currently being processed is submitted.',
        BATCH_ALREADY_IN_PROGRESS: 'Please wait while the batch currently being processed is submitted.'
      },
      success: {
        SUBMIT: 'Batch has been successfully submitted.',
        CANCEL: 'Batch has been successfully cancelled.',
        REPLICATE: 'Batch has been successfully replicated.',
        UPDATE: 'Batch has been successfully updated.',
        UPDATE_VARIATION: 'Variation has been successfully updated in this batch.',
        UPDATE_INVOICE: 'Purchase Invoice has been successfully updates in this batch.',
        DELETE: 'Batch has been successfully deleted.',
        REMOVE_PRODUCT: 'Product combination has been successfully deleted from this batch.',
        EXPORT: 'Batch has been successfully exported.'
      }
    }
  },
  email_export: {
    EMAIL_XLS: 'Exported file will be sent to your email address',
    NO_RECORDS: 'No records available with current status',
    BLANK_DISPATCH: 'No products available in current dispatch',
  },
  dispatch: {
    NO_DISPATCH: 'No dispatch available',
    NO_PRODUCT_FOUND: 'No product available',
    NO_PRODUCT: 'No product available',
    NO_DISPATCH_SUBMIT: 'Dispatch is not submitted',
    DISPATCH_ALREADY_SUBMIT: 'Dispatch is already submitted',
    DISPATCH_ALREADY_EXIST: 'Dispatch is already exists',
    DISPATCH_ALREADY_UNDER_PROCESSING: 'Dispatch is already under processing',
    INVALID_VARIATIONS_FOUND: 'Some size options not found. Kindly review the size options and update the details.'
  },
  access_denied: 'Your access permission is denied for this page, contact brand for more information',
  schedule_board: {
    error: {
      SELECT_CATALOGUE: (module: string) => `Please select catalogue in this ${module}`
    }
  },
  trips: {
    success: {
      UPDATE: (tripValue: any) => 'Trip has been successfully updated'
    },
    error: {
      UNABLE_TO_EDIT: 'You can\'t update this trips'
    }
  },
  meetings: {
    FOLLOWUP_EXISTS: 'FollowUp Meeting already exists for this meeting',
    ALREADY_ACCEPTED: 'This invitation has been already accepted',
    ALREADY_REJECTED: 'This invitation has been already rejected',
    UNABLE_TO_EDIT: 'You can\'t update this trips'
  },
  reports: {
    EXPORT_LIMIT: (limit: number = 5000) => `We can't export more than ${limit} records, please enhance filter criteria`
  }
};

export const deactivateOrg = (brandName: string) => {
  return `You are deactivated by ${brandName}, Contact to brand's owner for more info.`;
};

export const blockedOrg = (brandName: string) => {
  return `You are blocked by ${brandName}, Contact to brand's owner for more info.`;
};
