import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { ApiService } from "../../services/api";
import { FetchBrands, SetSelectedBrand } from "./actions";
import { ROUTES_CONFIG } from "../../routes";
import { tap } from "rxjs/operators";

@State<any>({
  name: 'app',
  defaults: {}
})

@Injectable()
export class AppState {
  constructor(
    private _api: ApiService
  ) { }

  @Action(FetchBrands, { cancelUncompleted: true })
  onFetchBrands(
    { setState, getState }: StateContext<any>,
    { payload }: FetchBrands
  ): any {
    const API_URL = ROUTES_CONFIG.GET_BRAND;
    return this._api.get(API_URL, {
      params: payload
    }).pipe(
      tap((res: any) => {
        if (res?.status?.toLowerCase() === 'success') {
          const seletedBrand = res.brands.find(brand => brand.selected) || res.brands[0] || {};
          setState({
            ...getState(),
            brands: {
              list: [...res.brands],
              selected: seletedBrand
            }
          })
        }
      })
    )
  }

  @Action(SetSelectedBrand, { cancelUncompleted: true })
  onSetSelectedBrand(
    { setState, getState }: StateContext<any>,
    { payload }: SetSelectedBrand
  ): any {
    setState({
      ...getState(),
      brands: {
        ...getState().brands,
        selected: payload
      }
    })
  }
}
