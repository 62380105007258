import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
// import * as jwt_decode from 'jwt-decode';
import jwt_decode from 'jwt-decode';
import * as CryptoJS from 'crypto-js';
import { CONSTANT } from '../constant';
import { ROUTES_CONFIG } from '../routes';
import { ApiService } from './api';
@Injectable({
  providedIn: 'root'
})

export class AuthService {

  /**
   * variable use when user enter mobile number in login page
  */
  public userMobileNumber = '';
  public countryCode = '';
  public viaLoginPage = false;
  public isNewSignUp = false;
  public reloadDashboardPage = false;
  public showHeaderMenu = false;
  public autoOpenOrganizationModel = false;
  SECRET_KEY = CONSTANT.CRYPT_KEY;
  // validate complete signup / login process
  public isSignupProcessComplete = false;
  public isLoginProcessComplete = false;

  userMobileNumberUpdate: Subject<string> = new Subject<string>();
  countryCodeUpdate: Subject<string> = new Subject<string>();
  viaLoginPageUpdate: Subject<boolean> = new Subject<boolean>();
  isNewSignUpUpdate: Subject<boolean> = new Subject<boolean>();
  reloadDashboardPageUpdate: Subject<boolean> = new Subject<boolean>();
  updateShowHeaderMenu: Subject<boolean> = new Subject<boolean>();
  updateAutoOpenOrganizationModel: Subject<boolean> = new Subject<boolean>();

  updateIsSignupProcessComplete: Subject<boolean> = new Subject<boolean>();
  updateIsLoginProcessComplete: Subject<boolean> = new Subject<boolean>();
  isUserDetailSuccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * save searched number for add new retailer
   */
  searchedMobileNumberForRetailer: number;
  saveSearchedMobileNumberForRetailer: Subject<number> = new Subject<number>();

  // ------------------------
  // dashboard page variables
  public isImageCropperPopupClosed = false;
  imageCropperPopupClosed: Subject<boolean> = new Subject<boolean>();
  // ------------
  constructor(
    private storage: StorageService,
    private _api: ApiService
  ) {
    this.countryCodeUpdate.subscribe((value) => {
      this.countryCode = value;
      this.storage.set('countryCode', value);
    });

    this.userMobileNumberUpdate.subscribe((value) => {
      this.userMobileNumber = value;
      this.storage.set('mobileNumber', value);
    });

    this.viaLoginPageUpdate.subscribe((value) => {
      this.viaLoginPage = value;
      this.storage.set('viaLoginPage', value);
    });

    this.isNewSignUpUpdate.subscribe((value) => {
      this.isNewSignUp = value;
      this.storage.set('isNewSignUp', value);
    });

    this.reloadDashboardPageUpdate.subscribe((value) => {
      this.reloadDashboardPage = value;
      this.storage.set('reloadDashboardPage', value);
    });

    this.imageCropperPopupClosed.subscribe((value) => {
      this.isImageCropperPopupClosed = value;
    });

    this.updateShowHeaderMenu.subscribe(value => {
      this.showHeaderMenu = value;
      this.storage.set('showHeaderMenu', value);
    });

    this.updateAutoOpenOrganizationModel.subscribe(value => {
      this.autoOpenOrganizationModel = value;
      this.storage.set('autoOpenOrganizationModel', value);
    });

    /**
     * isSignupProcessComplete and isLoginProcessComplete
     * both variable use to manage session
     */
    this.updateIsSignupProcessComplete.subscribe(value => {
      this.isSignupProcessComplete = value;
      this.storage.set('isSignupProcessComplete', value);
    });

    this.updateIsLoginProcessComplete.subscribe(value => {
      this.isLoginProcessComplete = value;
      this.storage.set('isLoginProcessComplete', value);
    });

    // --
    this.saveSearchedMobileNumberForRetailer.subscribe(value => {
      this.searchedMobileNumberForRetailer = value;
    });
  }

  /**
   * Check for already login
   */
  isLoggedIn() {
    let isUserLogin = false;
    const userAuth = this.storage.get(environment.auth_token_key);

    let isAllowToLogin = false;
    if (
      this.storage.get('isSignupProcessComplete') === true
      || this.storage.get('isLoginProcessComplete') === true) {
      isAllowToLogin = true;
    }
    if (
      (typeof userAuth !== 'undefined' && typeof userAuth !== null && userAuth.trim().length > 0) &&
      (typeof isAllowToLogin !== 'undefined' && typeof isAllowToLogin !== null && isAllowToLogin === true)
    ) {
      isUserLogin = true;
    } else {
      this.updateIsSignupProcessComplete.next(true);
      this.updateIsLoginProcessComplete.next(true);
      // this.storage.clearSessionKeys();
    }
    return isUserLogin;
  }
  /**
   * Get details from storage
   */
  getStorageData() {
    const userData = {
      userMobileNumber: this.storage.get('mobilenumber'),
      countryCode: this.storage.get('countrycode'),
      userId: this.storage.get('userid'),
      countryId: 'India',
      userObj: this.storage.get('userobject'),
    };
    return userData;
  }

  decodeJwtToken(token) {
    return jwt_decode(token);
  }

  decrypt(data) {
    data = CryptoJS.AES.decrypt(data, this.SECRET_KEY).toString(CryptoJS.enc.Utf8);
    return data;
  }

  logout() {
    const apiUrl = ROUTES_CONFIG.USER_LOGOUT;
    const existingToken = this.storage.get('device_token');
    let device: any;
    if (existingToken != undefined) {
      device = {
        id: existingToken,
        push_token: existingToken,
        name: 'Browser',
        os: 'Browser',
        os_version: '2.0'
      }
    } else {
      device = {};
    }
    const apiParams = { device };
    return this._api.post(apiUrl, apiParams);
  }
}
