import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  headerNotifyDropDown = new BehaviorSubject(false);
  headerNotifyCount = new BehaviorSubject(0);
  isChangeNotifyCount = this.headerNotifyCount.asObservable();

  isChangeNotifyDrop = this.headerNotifyDropDown.asObservable();

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * Get User Detail
   * @param apiRoute api route path
   * @param apiParamsObject params object
   */
  getUserDetail(apiRoute: string, apiParamsObject: ApiParamsObject): Observable<any> {

    // set params options
    const params = new HttpParams().set('cc', apiParamsObject.cc);
    const options = {
      params: apiParamsObject
    };
    return this.httpClient.get(apiRoute, { params });
  }

  getDashboardPageData(apiRoute: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/html; charset=utf-8');
    return this.httpClient.get(apiRoute, { headers, responseType: 'text' });
  }

  // fetch Performance Report
  saveUserDevice(apiRoute: string, apiParamsObject: any): Observable<any> {
    return this.httpClient.post<any>(apiRoute, apiParamsObject);
  }

  switchOrganization(apiUrl, apiParams) {
    return this.httpClient.post(apiUrl, apiParams);
  }

  setHeaderNotify(value) {
    this.headerNotifyCount.next(value);
  }

  setNotifyDropDown(value) {
    this.headerNotifyDropDown.next(value);
  }

  getNotifyDropDown() {
    return this.headerNotifyDropDown.value;
  }

}

interface ApiParamsObject {
  cc: string;
}
