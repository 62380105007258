import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { ApiService } from "../../services/api";
import {
  CreateRetailer,
  CreateRetailerOrg,
  FetchAllRetailers,
  FetchRetailerFilters,
  UpdateRetailer,
  ResetRetailerShared,
  UpdateRetailerStatus
} from "./actions";
import { ROUTES_CONFIG } from "../../routes";
import { tap } from "rxjs/operators";
import * as immutable from 'object-path-immutable';

interface IState {
  allRetailers?: {},

  filters?: {},

  errors?: {
    status: boolean;
    flag: string
  }
}

const initialState: IState = {};

@State<IState>({
  name: 'retailerShared',
  defaults: initialState
})

@Injectable()
export class RetailerSharedState {
  constructor(
    private _api: ApiService
  ) { }

  @Action(FetchAllRetailers, { cancelUncompleted: true })
  onFetchAllRetailers(
    { setState, getState }: StateContext<IState>,
    { payload }: FetchAllRetailers
  ): any {
    const API_URL = ROUTES_CONFIG.GET_RETAILERS;
    return this._api.get(API_URL, {
      params: payload
    }).pipe(
      tap((res: any) => {
        if (res?.status?.toLowerCase() === 'success') {
          let data: any = {
            all: {
              data: res.retailers || [],
              loaded: true,
              totalRecords: res.total_records
            }
          }
          if (payload?.type) {
            data = {
              [payload?.type?.toLowerCase()]: {
                data: res.retailers || [],
                loaded: true,
                totalRecords: res.total_records
              }
            }
          }
          setState({
            ...getState(),
            allRetailers: {
              ...getState().allRetailers,
              ...data
            }
          })
        } else {
          throw new Error()
        }
      }, err => {
        throw new Error()
      })
    )
  }

  @Action(FetchRetailerFilters, { cancelUncompleted: true })
  onFetchRetailerFilters(
    { setState, getState }: StateContext<IState>,
    { payload }: FetchRetailerFilters
  ): any {
    const API_URL = ROUTES_CONFIG.FILTER_RETAILER;
    return this._api.get(API_URL, {
      params: payload
    }).pipe(
      tap((res: any) => {
        if (res?.status?.toLowerCase() === 'success') {
          setState({
            ...getState(),
            filters: {
              data: res.search_filter || [],
              loaded: true
            }
          })
        } else {
          throw new Error()
        }
      }, err => {
        throw new Error()
      })
    )
  }

  @Action(CreateRetailer, { cancelUncompleted: true })
  onCreateRetailer(
    { setState, getState }: StateContext<IState>,
    { payload }: CreateRetailer
  ): any {
    const API_URL = ROUTES_CONFIG.SAVE_RETAILER;
    return this._api.put(API_URL, payload).pipe(
      tap((res: any) => {
        if (res?.status?.toLowerCase() === 'success') {
          setState({
            ...getState(),
          })
        } else {
          setState({
            ...getState(),
            errors: {
              status: true,
              flag: res.flag
            }
          })
          throw new Error()
        }
      }, err => {
        throw new Error()
      })
    )
  }

  @Action(UpdateRetailer, { cancelUncompleted: true })
  onUpdateRetailer(
    { setState, getState }: StateContext<IState>,
    { payload, retailerID }: UpdateRetailer
  ): any {
    const API_URL = ROUTES_CONFIG.UPDATE_RETAILER.replace(':retailer_id', retailerID);
    return this._api.post(API_URL, payload).pipe(
      tap((res: any) => {
        if (res?.status?.toLowerCase() === 'success') {
          setState({
            ...getState(),
          })
        } else {
          setState({
            ...getState(),
            errors: {
              status: true,
              flag: res.flag
            }
          })
          throw new Error()
        }
      }, err => {
        throw new Error()
      })
    )
  }

  @Action(UpdateRetailerStatus, { cancelUncompleted: true })
  onUpdateRetailerStatus(
    { setState, getState }: StateContext<IState>,
    { payload, retailerID, type }: UpdateRetailerStatus
  ): any {
    let API_URL = ROUTES_CONFIG.UPDATE_RETAILER.replace(':retailer_id', retailerID);
    if (type === 'block') {
      API_URL = `${API_URL}/${type}`;
    }
    return this._api.post(API_URL, payload).pipe(
      tap((res: any) => {
        if (res?.status?.toLowerCase() === 'success') {
          setState({
            ...getState(),
          })
        } else {
          setState({
            ...getState(),
            errors: {
              status: true,
              flag: res.flag
            }
          })
          throw new Error()
        }
      }, err => {
        throw new Error()
      })
    )
  }

  @Action(CreateRetailerOrg, { cancelUncompleted: true })
  onCreateRetailerOrg(
    { setState, getState }: StateContext<IState>,
    { payload }: CreateRetailerOrg
  ): any {
    const API_URL = ROUTES_CONFIG.ADD_RETAILER_ORG;
    return this._api.put(API_URL, payload).pipe(
      tap((res: any) => {
        if (res?.status?.toLowerCase() === 'success') {
          setState({
            ...getState(),
          })
        } else {
          setState({
            ...getState(),
            errors: {
              status: true,
              flag: res.flag
            }
          })
          throw new Error()
        }
      }, err => {
        throw new Error()
      })
    )
  }

  // Reset Retailer Shared
  @Action(ResetRetailerShared, { cancelUncompleted: true })
  restOrderReports({ setState, getState }: StateContext<IState>, { key }: ResetRetailerShared) {
    if (!key) {
      setState({
        ...initialState
      });
      return;
    }

    const state = immutable.set(
      getState(),
      `${key}`,
      immutable.get(initialState, `${key}`)
    );

    setState(state);
    return;
  }

}
