import { HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ROUTES_CONFIG } from '../../routes/index';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  public allCountries$: BehaviorSubject<Array<any>> = new BehaviorSubject(null);

  constructor(
    private httpClient: HttpClient
  ) { }


  /**
   * Get country list
   */
  getCountryList(): Observable<any> {
    // set params options
     return this.httpClient.get(ROUTES_CONFIG.COUNTRY_LIST);
  }

  /**
   * Get state list
   * @param countryId country id
   */
  getStateList(countryId: string): Observable<any> {
    const params = new HttpParams().set('id', countryId);
    return this.httpClient.get(ROUTES_CONFIG.STATE_LIST, {params});
  }

  /**
   * Get city list
   * @param stateId state id
   */
  getCityList(stateId: string): Observable<any> {
    const params = new HttpParams().set('id', stateId);
    return this.httpClient.get(ROUTES_CONFIG.CITY_LIST, {params});
  }
}
