import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private preloaderStateSource = new Subject<string>();

  preloaderState$ = this.preloaderStateSource.asObservable();
  private searchOverlaySource = new Subject<string>();
  private echartsSource = new Subject<boolean>();

  searchOverlayState$ = this.searchOverlaySource.asObservable();
  echartsState$ = this.echartsSource.asObservable();

  constructor(private httpClient: HttpClient) { }

  updatePreloaderState(state: string) {
    this.preloaderStateSource.next(state);
  }

  updateSearchOverlayState(state: string) {
    this.searchOverlaySource.next(state);
  }

  updateEChartsState(state: boolean) {
    this.echartsSource.next(state);
  }


  //fetch activity feed
  getActivityFeed(apiUrl: string, params): Observable<any> {
    return this.httpClient.get(apiUrl, {params});
  }
}
