export class CreateRetailer {
  static readonly type = '[Retailer Shared] Create Retailer';
  constructor(
    public payload: any
  ) { }
}

export class CreateRetailerOrg {
  static readonly type = '[Retailer Shared] Create Retailer Organisation';
  constructor(
    public payload: any
  ) { }
}

export class UpdateRetailer {
  static readonly type = '[Retailer Shared] Update Retailer';
  constructor(
    public retailerID: string,
    public payload: any
  ) { }
}

export class UpdateRetailerStatus {
  static readonly type = '[Retailer Shared] Update Retailer Status';
  constructor(
    public retailerID: string,
    public payload: any,
    public type: string = null
  ) { }
}

export class FetchRetailerFilters {
  static readonly type = '[Retailer Shared] Fetch Retailer Filters';
  constructor(
    public payload: any
  ) { }
}

export class FetchAllRetailers {
  static readonly type = '[Retailer Shared] Fetch All Retailers';
  constructor(
    public payload: any
  ) { }
}

export class ResetRetailerShared {
  static readonly type = '[Retailer Shared] Reset State';
  constructor(
    public key: string = null
  ) { }
}
