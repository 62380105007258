import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { ApiService } from "src/app/shared/services/api";
import {
  FetchScheduleFilter,
  FetchSelectionTeam,
  FetchTrip,
  CreateTrip,
  UpdateTrip,
  SetTrip,
  ResetScheduleShared,
  FetchTotalOrders,
  FetchTripCatalogue,
} from "./actions";
import { ROUTES_CONFIG } from "../../routes";
import { tap } from "rxjs/operators";
import { apiMessage } from 'src/app/shared/validation/apiErrorMessage';
import * as immutable from 'object-path-immutable';

interface IScheduleShared { }

const initialState = {} as IScheduleShared;

@State<IScheduleShared>({
  name: 'scheduleShared',
  defaults: {
    ...initialState
  }
})

@Injectable()
export class ScheduleSharedState {
  constructor(
    private _api: ApiService
  ) { }

  @Action(FetchScheduleFilter, { cancelUncompleted: true })
  onFetchScheduleFilter(
    { setState, getState }: StateContext<IScheduleShared>,
    { payload }: FetchScheduleFilter
  ): any {
    const { type, ...restPayload } = payload;
    const API_URL = this.getApiUrl(type);
    return this._api.post(API_URL, { ...restPayload }).pipe(
      tap((res: any) => {
        const { status, ...rest } = res;
        if (status?.toLowerCase() === 'success') {
          setState({
            ...getState(),
            filters: {
              data: {
                ...rest
              },
              loaded: true
            }
          })
        }
      })
    )
  }

  @Action(FetchSelectionTeam, { cancelUncompleted: true })
  onFetchSelectionTeam(
    { setState, getState }: StateContext<IScheduleShared>,
    { payload }: FetchSelectionTeam
  ): any {
    const { type, ...restPayload } = payload;
    return this._api.post(ROUTES_CONFIG.SCHEDULES_TEAMS, { ...restPayload }).pipe(
      tap((res: any) => {
        const { status, ...rest } = res;
        if (status?.toLowerCase() === 'success') {
          setState({
            ...getState(),
            teams: {
              data: {
                ...rest
              },
              loaded: true
            }
          })
        }
      })
    )
  }

  @Action(FetchTrip, { cancelUncompleted: true })
  onFetchTrip(
    { setState, getState }: StateContext<IScheduleShared>,
    { tripId, payload }: FetchTrip
  ): any {
    const API_URL = ROUTES_CONFIG.TRIP_DETAILS.replace(':id', tripId);
    return this._api.get(API_URL, {
      params: payload
    }).pipe(
      tap((res: any) => {
        if (res?.status?.toLowerCase() === 'success') {
          setState({
            ...getState(),
            trip: {
              data: res.trip,
              loaded: true
            }
          })
        } else {
          setState({
            ...getState(),
            error: {
              status: true,
              errorFlag: res.flag
            }
          })
          throw new Error(apiMessage.trips.error[res.flag] || '');
        }
      }, err => {
        throw new Error(err || '');
      })
    )
  }

  @Action(CreateTrip, { cancelUncompleted: true })
  onCreateTrip(
    { setState, getState }: StateContext<IScheduleShared>,
    { payload }: CreateTrip
  ): any {
    const apiUrl = ROUTES_CONFIG.TRIP_CREATE;
    return this._api.put(apiUrl, payload).pipe(
      tap((res: any) => {
        if (res.status.toLowerCase() === 'success') {

        } else {
          throw new Error('');
        }
      }, err => {
        throw new Error('');
      })
    );
  }

  @Action(UpdateTrip, { cancelUncompleted: true })
  onUpdateTrip(
    { setState, getState, dispatch }: StateContext<IScheduleShared>,
    { tripId, payload }: UpdateTrip
  ): any {
    const API_URL = ROUTES_CONFIG.TRIP_DETAILS.replace(':id', tripId);
    return this._api.post(API_URL, payload).pipe(
      tap((res: any) => {
        if (res?.status?.toLowerCase() === 'success') {
          dispatch(new SetTrip(res.trip));
        } else {
          setState({
            ...getState(),
            error: {
              status: true,
              errorFlag: res.flag
            }
          })
          throw new Error(apiMessage.trips.error[res.flag] || '');
        }
      }, err => {

      })
    )
  }

  @Action(SetTrip, { cancelUncompleted: true })
  onSetTrip(
    { setState, getState }: StateContext<IScheduleShared>,
    { payload }: SetTrip
  ): any {
    setState({
      ...getState(),
      trip: {
        data: payload,
        loaded: true
      }
    })
  }

  @Action(FetchTotalOrders, { cancelUncompleted: true })
  onFetchTotalOrders(
    { setState, getState }: StateContext<IScheduleShared>,
    { tripId, payload }: FetchTotalOrders
  ): any {
    let API_URL = ROUTES_CONFIG.TRIP_TOTAL_ORDERS.replace(':trip_id', tripId);
    return this._api.get(API_URL, {
      params: payload
    }).pipe(
      tap((res: any) => {
        if (res?.status?.toLowerCase() === 'success') {
          setState({
            ...getState(),
            totalOrders: {
              data: res.order,
              loaded: true
            }
          })
        } else {
          setState({
            ...getState(),
            error: {
              status: true,
              errorFlag: res.flag
            }
          })
          throw new Error(res?.body?.message || '');
        }
      }),
    )
  }

  @Action(FetchTripCatalogue, { cancelUncompleted: true })
  onFetchTripCatalogue(
    { setState, getState }: StateContext<IScheduleShared>,
    { tripId, payload }: FetchTripCatalogue
  ): any {
    let API_URL = ROUTES_CONFIG.TRIP_CATALOGUE.replace(':trip_id', tripId);
    return this._api.get(API_URL, {
      params: payload
    }).pipe(
      tap((res: any) => {
        if (res?.status?.toLowerCase() === 'success') {
          setState({
            ...getState(),
            catalogue: {
              data: res.catalogue,
              loaded: true
            }
          })
        } else {
          setState({
            ...getState(),
            error: {
              status: true,
              errorFlag: res.flag
            }
          })
          throw new Error(res?.body?.message || '');
        }
      }),
    )
  }

  // Reset Retailer Shared
  @Action(ResetScheduleShared, { cancelUncompleted: true })
  resetScheduleShared({ setState, getState }: StateContext<IScheduleShared>, { key }: ResetScheduleShared) {
    if (!key) {
      setState({
        ...initialState
      });
      return;
    }

    const state = immutable.set(
      getState(),
      `${key}`,
      immutable.get(initialState, `${key}`)
    );

    setState(state);
    return;
  }

  private getApiUrl(type: string = '') {
    let tempUrl = '';
    switch (type) {
      case 'resources':
        tempUrl = ROUTES_CONFIG.SCHEDULES_FILTER;
        break;
      case 'trips':
        tempUrl = ROUTES_CONFIG.TRIPS_FILTER;
        break;
      case 'meetings':
        tempUrl = ROUTES_CONFIG.MEETINGS_FILTER;
        break;
    }

    return tempUrl;
  }
}
