<h1 class="mb-0" mat-dialog-title [innerText]="dialogHeading"></h1>
<mat-dialog-content [innerHTML]="dialogTitle"></mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="dialogActionButtonObject.length > 0">
  <div class="card-action no-border overflow-hidden">
    <button *ngFor="let button of dialogActionButtonObject" mat-button [color]="button['className']" class="btn-w-sm"
    [innerText]="button['title']" [mat-dialog-close]="button['callBackRequire']"></button>
  </div>
</mat-dialog-actions>

<mat-dialog-actions class="text-right float-right" *ngIf="dialogActionButtonObject.length == 0">
  <div class="card-action no-border overflow-hidden">
    <div class="float-left">
      <button mat-button color="accent" class="btn-w-sm" [mat-dialog-close]="true">Okay</button>
      <div class="divider divider-sm"></div>
    </div>
  </div>
</mat-dialog-actions>
