export const environment = {
  production: false,
  hmr: false,
  env: 'dev',
  auth_token_key: 'dev.orderaxe.com',
  cdn: 'https://dev.assets.orderaxe.com',
  cms_url: 'https://dev.orderaxe.com',
  google_map_api: 'AIzaSyCNCEw8gD2CNCapMKH8LlN6AI64nEQGkBc',
  firebase: {
    apiKey: 'AIzaSyBZk800Nw8D45irzOBJgFCjAzFJmXDk04Q',
    authDomain: 'order-axe-38c27.firebaseapp.com',
    databaseURL: 'https://order-axe-38c27.firebaseio.com',
    projectId: 'order-axe-38c27',
    storageBucket: 'order-axe-38c27.appspot.com',
    messagingSenderId: '109257178845',
    appId: '1:109257178845:web:894ab1cb4508ba5c6c2534',
    measurementId: 'G-9TVTLFTN99'
  }
};
