import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ApiService } from 'src/app/shared/services/api';
import { FetchConsigneeList } from './actions';
import { ROUTES_CONFIG } from '../../routes';
import { tap } from 'rxjs/operators';

interface IState {
  allConsignee?: {};

  error?: {
    status: boolean;
    flag: string
  }
}

const initialState: IState = {};

@State({
  name: 'consigneeShared',
  defaults: {
    ...initialState
  }
})

@Injectable()
export class ConsigneeSharedState {
  constructor(
    private _api: ApiService
  ) { }

  @Action(FetchConsigneeList, { cancelUncompleted: true })
  onFetchConsigneeList(
    { setState, getState }: StateContext<IState>,
    { payload }: FetchConsigneeList
  ): any {
    const { org_id: orgId, ...restPayload } = payload;
    const API_URL = ROUTES_CONFIG.CONSIGNEE.replace(':org_id', orgId);
    return this._api.get(API_URL, {
      params: payload
    }).pipe(
      tap((res: any) => {
        if (res?.status?.toLowerCase() === 'success') {
          setState({
            ...getState(),
            allConsignee: {
              data: [...res.consignees],
              totalRecords: res.total_records,
              loaded: true
            },
            error: null
          })
        } else {
          setState({
            ...getState(),
            error: {
              status: true,
              flag: res.flag || ''
            }
          })
        }
      }, err => {
        setState({
          ...getState(),
          error: {
            status: true,
            flag: ''
          }
        })
      })
    )
  }
}
