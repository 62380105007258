import { AppState } from "./app";
import { ConsigneeSharedState } from "./consignee";
import { DispatchSharedState } from "./dispatch";
import { ScheduleSharedState } from "./schedule";
import { RetailerSharedState } from './retailers';

export const RootStates: Array<any> = [
  DispatchSharedState,
  ScheduleSharedState,
  AppState,
  RetailerSharedState,
  ConsigneeSharedState
]
