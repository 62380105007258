import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ROUTES_CONFIG } from 'src/app/shared/routes';
import { ICreateDispatch } from '../interfaces';


@Injectable({
  providedIn: 'root',
})
export class ChallansService {
  challanType$: BehaviorSubject<string> = new BehaviorSubject(null);
  openedChallanSection = new BehaviorSubject(0);
  private apiURL: string;
  private invoiceApiURL: string;
  private newAnvoiceApiURL: string;
  private generateInvoiceURL: string;

  constructor(private http: HttpClient) {
    this.apiURL = ROUTES_CONFIG.CHALLANS;
    this.invoiceApiURL = ROUTES_CONFIG.INVOICES;
    this.newAnvoiceApiURL = ROUTES_CONFIG.NEW_INVOICES;
    this.generateInvoiceURL = ROUTES_CONFIG.NEW_GENERATE_INVOICE;
  }

  public getFilter(apiParams: any): Observable<any> {
    return this.http.post(`${this.apiURL}/filters`, apiParams);
  }

  public getChallanList(type: string, apiParams, queryParams?: any): Observable<any> {
    return this.http.post(`${this.apiURL}/fetch/${type}`, apiParams, {
      params: queryParams
    });
  }

  public getChallanDetail(challanID: string, bodyParams, apiParam) {
    return this.http.post(`${this.apiURL}/${challanID}/fetch/json`, bodyParams, {
      params: apiParam
    });
  }

  public updateStatus(challanId: string, apiParams: any) {
    return this.http.post(`${this.apiURL}/${challanId}`, apiParams);
  }

  public exportData(
    exportType: string,
    challanID: string = null,
    bodyParams?: any,
    params?: any
  ) {
    if (challanID !== null) {
      return this.http.post(
        `${this.apiURL}/${challanID}/fetch/${exportType}`, bodyParams,
        {
          params,
          responseType: 'blob' as 'blob',
        }
      );
    } else {
      return this.http.post(`${this.apiURL}/fetch/${exportType}`, bodyParams, {
        responseType: 'blob' as 'blob',
      });
    }
  }

  public exportInvoiceData(
    exportType: string,
    invoiceID: string = null,
    bodyParams?: any
  ) {
    if (invoiceID !== null) {
      return this.http.post(
        `${this.invoiceApiURL}/${invoiceID}/fetch/${exportType}`,
        bodyParams,
        {
          responseType: 'blob' as 'blob',
        }
      );
    } else {
      return this.http.post(`${this.invoiceApiURL}/fetch/${exportType}`, bodyParams, {
        responseType: 'blob' as 'blob',
      });
    }
  }

  public getGenerateInvoice(bodyParams: any) {
    return this.http.post(`${this.generateInvoiceURL}`, bodyParams);
  }

  public generateInvoice(bodyParams: any): Observable<any> {
    return this.http.post(`${this.invoiceApiURL}`, bodyParams);
  }

  public getInvoiceDetail(invoiceID: string, apiParam) {
    return this.http.post(`${this.newAnvoiceApiURL}/${invoiceID}/fetch/json`, apiParam);
  }

  public replicateInvoice(challanID: string, bodyParams: any) {
    return this.http.post(`${this.apiURL}/${challanID}/replicate`, bodyParams);
  }

  public getDistributor(orgID: string): Observable<any> {
    return this.http.get(`${ROUTES_CONFIG.GET_DISTRIBUTOR}`, {
      params: { org_id: orgID },
    });
  }

  public getRetailers(reqParams: any): Observable<any> {
    return this.http.get(`${ROUTES_CONFIG.GET_RETAILERS}`, {
      params: reqParams,
    });
  }

  public createDispatch(params: ICreateDispatch): Observable<any> {
    const { dispatch_type, order_id, ...rest } = params;
    let API_URL = ROUTES_CONFIG.DISPATCH_CREATE;
    if (dispatch_type === 'unfulfilled') {
      API_URL = ROUTES_CONFIG.DISPATCH_UNFULFILLED.replace(':order_id', order_id);
      return this.http.post(`${API_URL}`, rest);
    }
    return this.http.put(`${API_URL}`, rest);
  }

  public updateChallan(challanId: string, body: any): Observable<any> {
    return this.http.post(`${this.apiURL}/${challanId}/update`, body);
  }

  public fetchExitingDispatch(orgId: string): Observable<any> {
    return this.http.put(`${ROUTES_CONFIG.DISPATCH_EXISTING}`, { org_id: orgId });
  }
}
