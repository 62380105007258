import { MatDialog } from '@angular/material/dialog';
import { CONSTANT } from './../constant';
import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import * as CryptoJS from 'crypto-js';
import * as SecureStorage from 'secure-web-storage';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public currentUserSubject = new BehaviorSubject<any>({});
  public currentUser = this.currentUserSubject.asObservable();
  SECRET_KEY = CONSTANT.CRYPT_KEY;
  userId = new BehaviorSubject('');
  userIDRef = this.userId.asObservable();
  localStore = {
    cdn: '',
    vialoginpage: '',
    userobject: '',
    defaultorganizationname: '',
    refererurl: '',
    defaultorganizationlogo: '',
    isloginprocesscomplete: '',
    device_token: '',
    issignupprocesscomplete: '',
    defaultorganizationtype: '',
    countrycode: '',
    defaultorganizationid: '',
    userid: '',
    showheadermenu: '',
    autoopenorganizationmodel: '',
    mobilenumber: '',
  }
  constructor(
    private storage: LocalStorageService,
    public dialogRef: MatDialog,
    private util: UtilService
  ) {
    this.userIDRef.subscribe(res => {
    });
  }

  public secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
      key = CryptoJS.SHA256(key, this.SECRET_KEY);
      return key.toString();
    },
    encrypt: function encrypt(data) {
      data = CryptoJS.AES.encrypt(JSON.stringify(data), this.SECRET_KEY).toString();
      data = data.toString();
      return data;
    },
    decrypt: function decrypt(data) {
      data = CryptoJS.AES.decrypt(data, this.SECRET_KEY);
      data = data.toString(CryptoJS.enc.Utf8);
      return data;
    }
  });

  encrypt(data) {
    if (typeof data !== 'string') {
      data = CryptoJS.AES.encrypt(JSON.stringify(data), this.SECRET_KEY).toString();
    } else {
      data = CryptoJS.AES.encrypt(data, this.SECRET_KEY);
    }
    return data;
  }

  decrypt(data) {
    data = CryptoJS.AES.decrypt(data, this.SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  }

  /**
   * Save key value pair in session
   * @param key keyName
   * @param value value
   */
  set(key: string, value: any) {
    const newVal = value;
    // const newVal = this.encrypt(value);
    this.storage.store(key, newVal);
  }

  /**
   * Get value using key
   * @param key keyName
   */
  get(key: string) {
    return this.storage.retrieve(key) || '';
  }

  getAsObservable(key: string) {
    return of(this.get(key))
  }

  /**
   * Clear all session / key only
   * @param key keyName
   */
  clear(key: string = '') {
    if (key.trim()) {
      this.storage.clear(key);
    } else {
      this.storage.clear();
    }
  }

  /**
   * Clear all session keys
   * Expect auth token
   */

  clearSessionKeys() {
    this.clear();
    this.dialogRef.closeAll();
  }
  updateSelectedOrgDetail(data, from?: string, updateOrg = false) {
    this.set('defaultorganizationid', data.id);
    this.set('defaultOrganizationName', data.name);
    this.set('defaultOrganizationType', data.type);
    this.set('defaultOrganizationLogo', data.logo);
    this.set('defaultDashboard', data.dashboard_type !== undefined ? data.dashboard_type : 'd2');

    // if (updateOrg) {
    const userobject = JSON.parse(JSON.stringify(this.get('userobject')));
    userobject.organisations = [...userobject.organisations].map((org: any) => {
      org.selected = org.id === data.id;
      return org;
    });
    this.set('userobject', userobject);
    // }
    this.setPermission(data);
    this.userId.next(data.id);
  }

  updateUserId(userId: string): void {
    this.userId.next(userId);
  }

  updateUser(data) {
    let userObj = this.get('userobject');
    userObj = {
      ...userObj,
      user: {
        ...userObj.user,
        ...data
      }
    };
    this.set('userobject', userObj);
  }

  updateOrganisations(organisations) {
    const userobject = this.get('userobject');
    userobject.organisations = organisations;
    this.set('userobject', userobject);
  }

  setPermission(user: any) {
    const userData = {
      name: user.name,
      permissions: user.permissions,
      role: user.role,
      active: user.is_team_member_active
    };
    if (['Owner', 'Admin'].indexOf(user.role) > -1) {
      userData.permissions = CONSTANT.PERMISSION;
    }
    this.currentUserSubject.next(userData);
  }

  getUserPermission() {
    const user = this.currentUserSubject.getValue();
    return user;
  }

  checkUserActive() {
    const currentUser = this.getUserPermission();
    return currentUser.active;
  }

  checkPermission(module, permissionToBeChecked) {
    let userPermissions = [];
    let hasPermission = false;
    const currentUser = this.getUserPermission();
    if (currentUser !== undefined && !this.util.isEmptyObject(currentUser) && Object.keys(currentUser).length !== 0) {
      userPermissions = currentUser.permissions[module];
      if (userPermissions !== undefined) {
        for (const checkPermission of permissionToBeChecked) {
          const permissionFound = userPermissions.find(x => x.toUpperCase() === checkPermission.toUpperCase());
          if (permissionFound) {
            hasPermission = true;
          }
        }
      }
      return hasPermission;
    }
  }

  checkPermissionById(module, permissionToBeChecked, orgId: string) {
    let userPermissions = [];
    let hasPermission = false;
    const selectedOrg = this.get('userobject').organisations.find(x => x.id === orgId);
    if (!selectedOrg) {
      return hasPermission;
    }
    const userData = {
      name: selectedOrg.name,
      permissions: selectedOrg.permissions,
      role: selectedOrg.role,
      active: selectedOrg.is_team_member_active
    };
    if (['Owner', 'Admin'].indexOf(selectedOrg.role) > -1) {
      userData.permissions = CONSTANT.PERMISSION;
    }
    if (userData !== undefined && Object.keys(userData).length !== 0) {
      userPermissions = userData.permissions[module];
      if (userPermissions !== undefined) {
        for (const checkPermission of permissionToBeChecked) {
          const permissionFound = userPermissions.find(x => x.toUpperCase() === checkPermission.toUpperCase());
          if (permissionFound) {
            hasPermission = true;
          }
        }
      }
      return hasPermission;
    }
  }

  checkOrderPermission(permissionToBeChecked, isSelf: boolean) {
    let userPermissions: any;
    let hasPermission = false;
    const currentUser = this.getUserPermission();
    if (currentUser !== undefined && Object.keys(currentUser).length !== 0) {
      userPermissions = currentUser.permissions;
      if (userPermissions.all_orders.indexOf(permissionToBeChecked) > -1) {
        hasPermission = true;
      } else if (userPermissions.self_orders.indexOf(permissionToBeChecked) > -1 && isSelf) {
        hasPermission = true;
      }
      return hasPermission;
    }
  }
}
