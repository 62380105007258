export class FetchScheduleFilter {
  static readonly type = '[Schedule Shared] Fetch Schedule Filters';
  constructor(
    public payload: any
  ) { }
}
export class FetchSelectionTeam {
  static readonly type = '[Schedule Shared] Fetch Selection Team';
  constructor(
    public payload: any
  ) { }
}

export class FetchTrip {
  static readonly type = '[Schedule Shared] Fetch Trip Detail';
  constructor(
    public tripId: string,
    public payload: any
  ) { }
}

export class CreateTrip {
  static readonly type = '[Schedule Shared] Create Trip';
  constructor(
    public payload: any
  ) { }
}

export class UpdateTrip {
  static readonly type = '[Schedule Shared] Update Trip';
  constructor(
    public tripId: string,
    public payload: any
  ) { }
}

export class SetTrip {
  static readonly type = '[Schedule Shared] Set Trip Detail';
  constructor(
    public payload: any
  ) { }
}

export class FetchTotalOrders {
  static readonly type = '[Trip] Fetch Trip Total Orders';
  constructor(
    public tripId: string,
    public payload: any
  ) { }
}
export class FetchTripCatalogue {
  static readonly type = '[Trip] Fetch Trip Catalogue';
  constructor(
    public tripId: string,
    public payload: any
  ) { }
}


export class ResetScheduleShared {
  static readonly type = '[Schedule Shared] Reset State';
  constructor(
    public key: string = null
  ) { }
}
