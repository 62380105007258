import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }


  public get(route: string, options: any = {}): Observable<any> {
    return this.http.get(route, {
      ...options
    })
  }

  public post(route: string, body: any, options: any = {}): Observable<any> {
    return this.http.post(route, body, {
      ...options
    })
  }

  public put(route: string, body: any, options: any = {}): Observable<any> {
    return this.http.put(route, body, {
      ...options
    })
  }

  public patch(route: string, body: any, options: any = {}): Observable<any> {
    return this.http.patch(route, body, {
      ...options
    })
  }
}
