import { NgModule, ApplicationRef, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, ɵDomSanitizerImpl } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// common module
import { COMMON_MODULE_IMPORT } from './shared/modules/common-module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpModule } from '@angular/http';

import { AuthenticationInterceptor } from './shared/interceptor/authentication.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderDirective } from './modules/layout/preloader.directive';
import { removeNgStyles, createNewHosts } from '@angularclass/hmr';
import { NgxImageCompressService } from 'ngx-image-compress';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AsyncPipe, CurrencyPipe, LowerCasePipe, TitleCasePipe } from '@angular/common';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false
};
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
import { MATERIAL_MODULE_IMPORTS } from './shared/modules/mat-module';
import { ConfirmationDialogComponent } from './shared/components';
import { environment } from 'src/environments/environment';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { RootStates } from './shared/stores';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";

class MyErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    console.error(error);
  }
}
import { NgxWebstorageModule } from 'ngx-webstorage';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    COMMON_MODULE_IMPORT,
    HttpClientModule,
    MATERIAL_MODULE_IMPORTS,
    ToastrModule.forRoot(),
    NgxsModule.forRoot(RootStates, {
      developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'OrderAxe Web App',
    }),
    NgxWebstorageModule.forRoot()
  ],
  declarations: [
    AppComponent,
    PreloaderDirective,
    ConfirmationDialogComponent,
  ],
  exports: [],
  providers: [
    AsyncPipe,
    TitleCasePipe,
    LowerCasePipe,
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    NgxImageCompressService,
    ɵDomSanitizerImpl,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: ErrorHandler, useClass: MyErrorHandler },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(public appRef: ApplicationRef) {
  }


  hmrOnInit() { }
  hmrOnDestroy(store) {
    const cmpLocation = this.appRef.components.map(
      cmp => cmp.location.nativeElement
    );
    // recreate elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // remove styles
    removeNgStyles();
  }
  hmrAfterDestroy(store) {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
