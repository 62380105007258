import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularEditorModule } from '@kolkov/angular-editor';

export const COMMON_MODULE_IMPORT = [
  FormsModule,
  ReactiveFormsModule,
  AngularEditorModule,
];

