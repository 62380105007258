export class FetchBrands {
  static readonly type = '[App] Fetch All Brands';
  constructor(
    public payload: any
  ) { }
}

export class SetSelectedBrand {
  static readonly type = '[App] Set Selected Brand';
  constructor(
    public payload: any
  ) { }
}
