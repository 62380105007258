
import { Injector } from '@angular/core';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";

export let appInjector: Injector;

Sentry.init({
  dsn: "https://30693a2442f6c1393c7826a28accd7e1@o4507298248654848.ingest.us.sentry.io/4507298441134080",
  environment: environment.env,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://cms.orderaxe.com'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: !!window.location.origin.includes('orderaxe.com')
});

if (environment.production) {
  enableProdMode();
}

/* For console nothing in production mode */
if (['prod', 'stage'].includes(environment.env)) {
  if (window) {
    window.console.log = window.console.info = () => {
      // Don't log anything.
    };
  }
}

platformBrowserDynamic().bootstrapModule(AppModule).then(
  (componentRef) => {
    appInjector = componentRef.injector;
  });
