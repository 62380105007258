import { ROUTES_CONFIG } from 'src/app/shared/routes';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from 'src/app/shared/services/storage.service';
@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  switchOrganization(apiUrl, apiParams) {
    return this.http.post(apiUrl, apiParams);
  }

  logout() {
    const apiUrl = ROUTES_CONFIG.USER_LOGOUT;
    const existingToken = this.storageService.get('device_token');
    let device: any;
    if (existingToken != undefined) {
      device = {
        id: existingToken,
        push_token: existingToken,
        name: 'Browser',
        os: 'Browser',
        os_version: '2.0'
      }
    } else {
      device = {};
    }
    const apiParams = { device };
    return this.http.post(apiUrl, apiParams);
  }

}
