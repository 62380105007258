import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  public dialogData: Array<string> = [];

  public dialogHeading = '';
  public dialogTitle = '';
  public dialogActionButtonObject: Array<object> = [];

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      if (typeof data.heading !== 'undefined' && data.heading !== null && data.heading.trim() !== '') {
        this.dialogHeading = data.heading;
      }
      if (typeof data.heading !== 'undefined' && data.heading !== null && data.heading.trim() !== '') {
        this.dialogTitle = data.title;
      }

      if (typeof data.buttonObject !== 'undefined' && data.buttonObject !== null && data.buttonObject.length > 0) {
        this.dialogActionButtonObject = data.buttonObject;
      }
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
