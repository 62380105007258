import { AuthService } from 'src/app/shared/services/auth.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CONSTANT } from '../constant';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    readonly router: Router,
    private authService: AuthService,
    private storageService: StorageService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const refererUrl = state.url;
    // this.storageService.set('refererUrl', refererUrl);
    const status = this.checkLogin(route);
    return status;
  }


  protected checkLogin(route?: ActivatedRouteSnapshot) {
    const isLoggedIn = this.authService.isLoggedIn();
    const userObject = this.storageService.get('userObject');
    if (isLoggedIn && userObject) {
      if (route.queryParams.from === CONSTANT.orderVia) {
        this.router.navigate([route.queryParams.redirectTo]);
      } else {
        this.router.navigate(['/dashboard']);
      }
      return false;
    }
    return true;
  }
}
