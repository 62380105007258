import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Dialog Box
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

// Configuration for Snack Bar
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { DialogComponent } from '../components/dialog/dialog.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { ROUTES_CONFIG } from '../routes';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { ToWords } from 'to-words';
import { UntypedFormGroup } from '@angular/forms';
import { blockedOrg, deactivateOrg } from '../validation/apiErrorMessage';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const ExcelType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const ExcelExt = '.xls';
const headerStyle = {
  font: { name: 'Calibri' }
};

const generalStyle = {
  font: { name: 'Calibri', sz: 16, bold: true },
  alignment: { wrapText: true, horizontal: 'left' }
};

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  private typeOfOrgForm = 'Add';
  public displayEditOrgFrm: boolean;
  displayEditOrgFrmChange: Subject<boolean> = new Subject<boolean>();
  // dialog reference
  public dialogRef: any;

  currentCountry$: BehaviorSubject<any> = new BehaviorSubject(null);

  isChallanUpdate$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isChallanUpdateObseve$ = this.isChallanUpdate$.asObservable();

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private toast: ToastrService,
    private http: HttpClient
  ) {
    this.displayEditOrgFrm = false;
    this.displayEditOrgFrmChange.subscribe((value) => {
      this.displayEditOrgFrm = value;
    });
  }

  public setValue(value) {
    this.typeOfOrgForm = value;
  }

  public getValue() {
    return this.typeOfOrgForm;
  }


  public setModalVar(value) {
    this.displayEditOrgFrmChange.next(value);
  }

  public getModalVar() {
    return this.displayEditOrgFrm;
  }

  public dynamicSort(property) {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a, b) => {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  public removeDuplicates(originalArray, prop) {
    const newArray = [];
    const lookupObject = {};

    for (const i in originalArray) {
      if (i) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
      }
    }

    for (const i in lookupObject) {
      if (i) {
        newArray.push(lookupObject[i]);
      }
    }
    return newArray;
  }

  /*
  ** for removeing keys from object that have null or undefined values or blank values
  */
  public removeFalsyValues(obj) {
    for (const propName in obj) {
      if (!obj[propName] || !obj[propName]?.length || !Object.keys(obj[propName])?.length) {
        delete obj[propName];
      }
    }
    return obj;
  }


  /**
   * Error dialog box
   */
  openDialogBox(errorObject: any, isObject: boolean) {

    const errorMessage = this.errorMessageText(errorObject, isObject);
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      heading: 'Error!',
      title: errorMessage,
      buttonObject: [
        {
          title: 'Okay',
          className: 'accent',
          callBackRequire: false
        }
      ]
    };
    this.dialogRef = this.dialog.open(DialogComponent, dialogConfig);
  }

  /**
   *
   * @param errorObject error object
   */
  errorMessageText(errorObject: any, isObject: boolean = false) {
    let errorMessage = 'Something went wrong. please try again later.';
    if (isObject) {
      if (
        typeof errorObject.error.message !== 'undefined'
        && errorObject.error.message !== null
        && errorObject.error.message.trim() !== '') {
        errorMessage = errorObject.error.message;
      }
    } else if (!isObject) {
      if (
        typeof errorObject !== 'undefined'
        && typeof errorObject !== null
        && errorObject.trim() !== ''
      ) {
        errorMessage = errorObject;
      }
    }
    return (errorMessage);
  }

  /**
   *
   * @param statusCode status code
   */
  httpStatusErrorMessage(statusCode: number) {
    let errorMessage = 'Something went wrong. please try again later.';
    if (typeof statusCode !== 'undefined' && typeof statusCode !== null && statusCode > 0) {
      switch (statusCode) {
        case 400: {
          errorMessage = 'Parameters are invalid or incomplete.';
          break;
        }
        case 404: {
          errorMessage = 'Not a valid resource path.';
          break;
        }
        case 401: {
          errorMessage = 'Authorization not provided.';
          break;
        }
        case 403: {
          errorMessage = ' Authorization not valid/Expired.';
          break;
        }
        case 405: {
          errorMessage = 'Http method not supported for accessed resource.';
          break;
        }
        case 500: {
          errorMessage = 'Any error/exception occurred while processing request.';
          break;
        }
      }
    }
    return (errorMessage);
  }

  /**
   * Configuration for snack bar
   * @param dataObject data object
   */
  openSnackBar(dataObject: any) {
    this.snackBar.dismiss();
    const config = new MatSnackBarConfig();
    config.duration = dataObject.duration || 5000;
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.data = dataObject;

    switch (dataObject.status) {
      case 1: {
        this.toast.success(dataObject.message, null, {
          timeOut: dataObject.duration || 5000,
          progressBar: true,
        });
        break;
      }
      case 2: {
        this.toast.error(dataObject.message, null, {
          timeOut: dataObject.duration || 5000,
          progressBar: true,
        });
        break;
      }
      case 3: {
        this.toast.warning(dataObject.message, null, {
          timeOut: dataObject.duration || 5000,
          progressBar: true,
        });
        break;
      }
      case 4: {
        this.toast.info(dataObject.message, null, {
          timeOut: dataObject.duration || 5000,
          progressBar: true,
        });
        break;
      }
      case 10: {
        this.toast.info(dataObject.message, null, {
          timeOut: dataObject.duration || 5000,
          progressBar: true,
          toastClass: 'dark'
        });
        break;
      }
      default: {
        this.toast.success(dataObject.message, null, {
          timeOut: dataObject.duration || 5000,
          progressBar: true,
        });
        break;
      }
    }

    // this.snackBar.openFromComponent(ToastbarComponent, config);
  }

  isValidHttpUrl(urlData) {
    let url;

    try {
      url = new URL(urlData);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }


  /**
   * Check for undefined
   * @param key keyName
   */
  checkUndefined(key: string) {
    return (typeof key === undefined)
      ? key : '';
  }


  public isEmptyObject(obj) {
    if (!obj) {
      return true;
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  public isEmptyObjectKey(obj) {
    if (this.isEmptyObject(obj)) {
      return true;
    }
    for (const key in obj) {
      if (![undefined, null, ''].includes(obj[key])) {
        return false;
      }
    }
    return true;
  }

  public isSomeObjectKey(obj) {
    let keys = [];
    for (const key in obj) {
      if (!!obj[key]) {
        keys.push(key);
      }
    }
    return keys.length > 0;
  }


  sortSizeOrder(arrays, order) {
    const sortArray = [];
    let sortedArray = [];
    const i = 1;
    arrays.filter((array) => {
      order.forEach(data => {
        if (array === data.val && !sortArray.includes(data.val)) {
          sortArray[data.order - 1] = array;
        }
      });
    });

    arrays.forEach((data) => {
      if (!sortArray.includes(data)) {
        sortArray.push(data);
      }
    });

    sortedArray = sortArray.filter(array => array !== '');
    return sortedArray;
  }


  convertNumberToWords(amount) {
    return new ToWords({
      converterOptions: {
        currency: true,
        ignoreDecimal: true,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
      }
    }).convert(amount);
  }

  convertNumberToWordsBK(amount) {
    const words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    const atemp = amount.split('.');
    const numbers = atemp[0].split(',').join('');
    const nLength = numbers.length;
    let wordsString = '';
    if (nLength <= 9) {
      const nArray: Array<any> = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      const receivedNArray = new Array();
      for (let i = 0; i < nLength; i++) {
        receivedNArray[i] = numbers.substr(i, 1);
      }
      for (let j = 9 - nLength, k = 0; j < 9; j++, k++) {
        nArray[j] = receivedNArray[k];
      }

      for (let l = 0, m = 1; l < 9; l++, m++) {
        if (l === 0 || l === 2 || l === 4 || l === 7) {
          if (nArray[l] === 1) {
            nArray[m] = 10 + parseInt(nArray[m], 10);
            nArray[l] = 0;
          }
        }
      }
      let value: any = '';
      for (let n = 0; n < 9; n++) {
        if (n === 0 || n === 2 || n === 4 || n === 7) {
          value = nArray[n] * 10;
        } else {
          value = nArray[n];
        }
        if (value !== 0) {
          wordsString += words[value] + ' ';
        }
        if ((n === 1 && value !== 0) || (n === 0 && value !== 0 && nArray[n + 1] === 0)) {
          wordsString += 'Crores ';
        }
        if ((n === 3 && value !== 0) || (n === 2 && value !== 0 && nArray[n + 1] === 0)) {
          wordsString += 'Lacs ';
        }
        if ((n === 5 && value !== 0) || (n === 4 && value !== 0 && nArray[n + 1] === 0)) {
          wordsString += 'Thousand ';
        }
        if (n === 6 && value !== 0 && (nArray[n + 1] !== 0 && nArray[n + 2] !== 0)) {
          wordsString += 'Hundred and ';
        } else if (n === 6 && value !== 0) {
          wordsString += 'Hundred ';
        }
      }
      wordsString = wordsString.split('  ').join(' ');
    }
    return wordsString;
  }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  getCurrentCountry$() {
    return this.http.get(`${ROUTES_CONFIG.GET_CURRENT_COUNTRY}`);
  }

  getDirtyFormValues(form: UntypedFormGroup) {
    const dirtyValues = {};

    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      if (control.dirty) {
        dirtyValues[key] = control.value;
      }
    });

    return dirtyValues;
  }

  setLongAddress(addressObj: any = null): string {
    let address = '';
    let tempAddress = {
      address_line1: addressObj?.address_line1 || '',
      address_line2: addressObj?.address_line2 || '',
      city: addressObj?.city || '',
      state: addressObj?.state || '',
      country: addressObj?.country || '',
      zip: addressObj?.zip || ''
    };
    tempAddress = this.removeFalsyValues(tempAddress);
    address = Object.values(tempAddress).join(', ');
    return address;

    if (addressObj === undefined || addressObj === null) {
      return address;
    }
    if (addressObj.address_line1 !== undefined && addressObj.address_line1 !== null && addressObj.address_line1 !== '') {
      address += addressObj.address_line1 + ', ';
    }
    if (addressObj.address_line2 !== undefined && addressObj.address_line2 !== null && addressObj.address_line2 !== '') {
      address += addressObj.address_line2 + ', ';
    }
    if (addressObj.city !== undefined && addressObj.city !== null && addressObj.city !== '') {
      address += addressObj.city + ', ';
    }
    if (addressObj.state !== undefined && addressObj.state !== null && addressObj.state !== '') {
      address += addressObj.state + ' ';
    }
    if (addressObj.country !== undefined && addressObj.country !== null && addressObj.country !== '') {
      address += addressObj.country;
    }
    return address;
  }

  setMediumAddress(addressObj: any): string {
    let address = '';
    if (!addressObj || this.isEmptyObject(addressObj) || this.isEmptyObjectKey(addressObj)) {
      return address;
    }
    if (addressObj.city !== undefined && addressObj.city !== null && addressObj.city !== '') {
      address += addressObj.city + ', ';
    }
    if (addressObj.state !== undefined && addressObj.state !== null && addressObj.state !== '') {
      address += addressObj.state + ' ';
    }
    if (addressObj.country !== undefined && addressObj.country !== null && addressObj.country !== '') {
      address += addressObj.country + ' ';
    }
    if (addressObj.zip !== undefined && addressObj.zip !== null && addressObj.zip !== '') {
      address += addressObj.zip + ' ';
    }
    return address;
  }

  setShortAddress(addressObj: any): string {
    let address = '';
    if (addressObj === undefined || addressObj === null) {
      return address;
    }
    if (addressObj.city !== undefined && addressObj.city !== null && addressObj.city !== '') {
      address += addressObj.city + ', ';
    }
    if (addressObj.state !== undefined && addressObj.state !== null && addressObj.state !== '') {
      address += addressObj.state + ' ';
    }
    return address;
  }

  exportConfirmation(modalData?: any) {
    const data = {
      heading: modalData.heading || 'Are you sure?',
      text: modalData.text || 'You want to export xls',
      note: modalData.note || null,
      type: 'exportConfirm'
    };

    const exportConfirm = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      maxWidth: '96vw',
      panelClass: ['m-auto'],
      data
    });

    return exportConfirm.afterClosed();
  }

  sortAttributes(data, sortType, sortName) {
    switch (sortType) {
      case 'alphabetical':
        data = this.sortAlphabeticalAttr(data, sortName);
        break;
      case 'numeric':
        data = this.sortNumericAttr(data, sortName);
        break;
    }

    return data;
  }

  sortAlphabeticalAttr(data: Array<any>, sortName) {
    data.sort((a, b) => {
      const a1 = typeof a[sortName];
      const b1 = typeof b[sortName];
      return (a1 > b1) ? -1 : (a1 < b1) ? 1 : (a[sortName] < b[sortName]) ? -1 : (a[sortName] > b[sortName]) ? 1 : 0;
    });
    return data;
  }

  sortNumericAttr(data: Array<any>, sortName) {
    data.sort((a, b) => {
      const a1 = typeof a[sortName];
      const b1 = typeof b[sortName];
      return (a1 < b1) ? -1 : (a1 > b1) ? 1 : (a[sortName] < b[sortName]) ? -1 : (a[sortName] > b[sortName]) ? 1 : 0;
    });
    return data;
  }

  toOptionalFixed(num: any) {
    let value = num;
    const decimalValue = String(num).split('.');


    if (decimalValue.length > 1) {
      value = value.toFixed(2);
    }

    return value;
  }

  getStatusClass(status: string) {
    return status.toLowerCase().split(' ').join('-');
  }

  isMobileDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

  checkedBrandActive(selectedBrand: any) {
    if (
      selectedBrand !== undefined &&
      (selectedBrand.is_active_for_distributor === false || selectedBrand.retailer_blocked === true)
    ) {
      let messageData = {};

      if (selectedBrand.is_active_for_distributor === false) {
        messageData = {
          message: deactivateOrg(selectedBrand.name),
          status: 2
        };
      } else {
        if (selectedBrand.retailer_blocked === true) {
          messageData = {
            message: blockedOrg(selectedBrand.name),
            status: 2
          };
        }
      }

      this.openSnackBar(messageData);
      return false;
    }
    return true;
  }
  exportJsonToSheet(rows: any, fileName: string) {
    // const wb = XLSX.utils.table_to_book(table);
    // const sheetName = wb.SheetNames[0];
    // const sheet = wb.Sheets[sheetName];

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");


    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
    const updateFileName =
      fileName + '_export_' + new Date().getTime() + ExcelExt;

    // const range = XLSX.utils.decode_range(sheet['!ref']);
    // for (let R = range.s.r; R <= range.e.r; ++R) {
    //   for (let C = range.s.c; C <= range.e.c; ++C) {
    //     const cellAddress = { c: C, r: R };
    //     /* if an A1-style address is needed, encode the address */
    //     const cellRef = XLSX.utils.encode_cell(cellAddress);
    //   }
    // }
    this.saveAsExcel(excelBuffer, updateFileName);
  }

  exportTableToSheet(table: any, fileName: string) {
    const wb = XLSX.utils.table_to_book(table);
    const sheetName = wb.SheetNames[0];
    const sheet = wb.Sheets[sheetName];


    const excelBuffer: any = XLSX.write(wb, { bookType: 'xls', type: 'array' });
    const updateFileName =
      fileName + '_export_' + new Date().getTime() + ExcelExt;

    // const range = XLSX.utils.decode_range(sheet['!ref']);
    // for (let R = range.s.r; R <= range.e.r; ++R) {
    //   for (let C = range.s.c; C <= range.e.c; ++C) {
    //     const cellAddress = { c: C, r: R };
    //     /* if an A1-style address is needed, encode the address */
    //     const cellRef = XLSX.utils.encode_cell(cellAddress);
    //   }
    // }
    this.saveAsExcel(excelBuffer, updateFileName);
  }

  private saveAsExcel(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: ExcelType
    });
    FileSaver.saveAs(data, fileName);
  }

  public getFutureStock({ variation_qty_in_planning, quantity, quantity_in_production, ordered_quantity }): number {
    return ((variation_qty_in_planning || 0) + (quantity || 0) + (quantity_in_production || 0)) - (ordered_quantity || 0);
  }

  public onCopy(value: string): void {
    navigator.clipboard.writeText(value);
  }

}

