import { LoginGuard } from './shared/guard/login.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CheckUserGuard } from './shared/guard/check-user.guard';

const AppRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/layout/layout.module').then(layout => layout.LayoutModule),
    canActivate: [CheckUserGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(login => login.LoginModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'catalogues',
    loadChildren: () => import('./catalogue/catalogue.module').then(catalogue => catalogue.CatalogueModule),
    data: { title: 'Catalogue' }
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/pages').then(authenticate => authenticate.AuthenticateModule),
  },
  {
    path: 'page-not-found',
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
    data: { title: 'Page Not Found' }
  },
  {
    path: 'app-reports',
    loadChildren: () => import('./modules/pages').then(m => m.AppReportsModule),
    data: { title: 'Order Reports' }
  },
  {
    path: 'payments',
    loadChildren: () => import('./modules/pages').then(m => m.PaymentsModule),
    data: { title: 'Payment' }
  },
  {
    path: 'meeting-invite',
    loadChildren: () => import('./modules/pages').then(m => m.RsvpModule),
    data: { title: 'Invitation' }
  },
  // { path: '**', redirectTo: 'login', pathMatch: 'full' },
  {
    path: ':appId/catalogues',
    loadChildren: () => import('./catalogue/catalogue.module').then(catalogue => catalogue.CatalogueModule),
    data: { title: 'Catalogue' }
  },
  {
    path: '**',
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
    data: { title: 'Page Not Found' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
